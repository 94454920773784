<template>
  <div class="p-8">
    <div class="flex items-center mb-4">
      <h2 class="text-lg font-semibold">Admin</h2>
    </div>

    <!-- Search and Actions -->
    <div class="">
      <div class="flex justify-end mb-4">
        <button class="btn btn-blue ml-auto" @click="openCreateAdmin">
          Create Admin
        </button>
      </div>
      <div class="w-3/4">
        <search-form
          v-model="searchQuery"
          placeholder="Search for staff name, email"
          @submit="fetchAdmins"
        />
      </div>
    </div>

    <!-- Table -->
    <datatable
      ref="table"
      :ajax="true"
      :ajax-pagination="true"
      :url="usersUrlWithQuery"
      :query="searchQuery"
      :columns="columns"
    >
      <template v-slot:td-1="{ item: user }">
        <div class="text-sm font-semibold">
          {{ user.row.firstName || 'N/A' }} {{ user.row.lastName }}
        </div>
      </template>

      <template v-slot:td-2="{ item: user }">
        <div class="text-sm text-gray-600">{{ user.row.email }}</div>
      </template>

      <template v-slot:td-3="{ item: user }">
        <div class="text-sm">
          {{ user.row.roles?.length }} ({{
            user.row.roles?.[0]?.permissions?.slice(0, 3).join(', ')
          }}){{ user.row.permissions?.length > 3 ? '...' : '' }}
        </div>
      </template>
      <template v-slot:td-4="{ item: user }">
        <div class="flex gap-2">
          <button
            class="btn btn-blue text-xs"
            @click.prevent="editAdminModal(user.row)"
          >
            Edit Admin
          </button>

          <button
            class="btn btn-red text-xs"
            @click.prevent="openDeactivateModal(user.row)"
          >
            Deactivate Admin
          </button>

          <!-- Deactivate Confirmation Modal -->
          <modal
            ref="deactivateModal"
            className="w-11/12 sm:w-[480px] p-6 rounded-lg shadow-xl bg-white"
          >
            <template>
              <div class="text-center">
                <!-- Warning Icon -->
                <div
                  class="mx-auto flex items-center justify-center h-24 w-24 rounded-full bg-red-100 mb-6"
                >
                  <svg
                    class="h-16 w-16 text-red-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>

                <h3 class="text-xl font-medium text-gray-900 mb-2">
                  Deactivate Admin Account
                </h3>
                <div class="mt-2 px-7 py-3">
                  <p class="text-gray-500">
                    Are you sure you want to deactivate this admin account? This
                    action cannot be undone.
                  </p>
                </div>

                <!-- Action Buttons -->
                <div class="mt-5 flex justify-center gap-4">
                  <button
                    @click="$refs.deactivateModal.close()"
                    class="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 text-sm font-medium rounded-md"
                  >
                    Cancel
                  </button>
                  <button
                    @click="handleDeactivate"
                    class="px-4 py-2 bg-red-600 hover:bg-red-700 text-white text-sm font-medium rounded-md"
                  >
                    Yes, Deactivate
                  </button>
                </div>
              </div>
            </template>
          </modal>
        </div>
      </template>
    </datatable>

    <!-- Create Admin Modal -->
    <modal
      ref="createAdminModal"
      className="w-11/12 sm:w-3/5 lg:w-3/5 p-6 rounded-md shadow-lg bg-white overflow-auto h-[95%]"
    >
      <template>
        <div class="flex justify-between items-center border-b pb-4 px-10">
          <h2 class="text-xl font-semibold">Create Admin</h2>
        </div>

        <!-- Admin Form -->
        <div class="my-10 px-10 space-y-4 border border-slate-300 py-8">
          <div>
            <label class="text-lg font-medium text-gray-600 mb-1 inline-block"
              >Email</label
            >
            <input
              v-model="createAdminForm.email"
              type="email"
              class="w-full border p-2 rounded-md focus:outline-blue-500 py-4"
              placeholder="Enter email address"
            />
          </div>

          <div>
            <label class="text-lg font-medium text-gray-600 mb-1 inline-block"
              >Role</label
            >
            <select
              v-model="createAdminForm.role"
              class="w-full border p-2 rounded-md focus:outline-blue-500 py-4"
            >
              <option value="" disabled selected>Select a role</option>
              <option v-for="role in roles" :key="role.id" :value="role.id">
                {{ role.name }}
              </option>
            </select>
          </div>
        </div>

        <!-- Actions -->
        <div class="flex justify-end gap-2 mt-6 px-10 mb-4">
          <button
            @click="closeCreateAdminModal"
            class="px-4 py-2 border border-gray-500 text-gray-600 rounded-md hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            @click="createAdmin"
            class="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Submit
          </button>
        </div>
      </template>
    </modal>

    <!-- edit admin modal -->

    <modal
      ref="editAdminModal"
      className="w-11/12 sm:w-3/5 lg:w-3/5 p-6 rounded-md shadow-lg bg-white overflow-auto h-[95%]"
    >
      <template>
        <div class="flex justify-between items-center border-b pb-4 px-10">
          <h2 class="text-xl font-semibold">Edit Admin</h2>
        </div>

        <!-- Admin Form -->
        <div class="my-10 px-10 space-y-4 border border-slate-300 py-8">
          <div>
            <label class="text-lg font-medium text-gray-600 mb-1 inline-block"
              >First Name</label
            >
            <input
              v-model="editAdminForm.firstName"
              type="text"
              class="w-full border p-2 rounded-md focus:outline-blue-500 py-4"
              placeholder="Enter first name"
            />
          </div>

          <div>
            <label class="text-lg font-medium text-gray-600 mb-1 inline-block"
              >Last Name</label
            >
            <input
              v-model="editAdminForm.lastName"
              type="text"
              class="w-full border p-2 rounded-md focus:outline-blue-500 py-4"
              placeholder="Enter last name"
            />
          </div>

          <div>
            <label class="text-lg font-medium text-gray-600 mb-1 inline-block"
              >Phone Number</label
            >
            <input
              v-model="editAdminForm.phone"
              type="text"
              class="w-full border p-2 rounded-md focus:outline-blue-500 py-4"
              placeholder="Enter phone number"
            />
          </div>
        </div>

        <!-- Actions -->
        <div class="flex justify-end gap-2 mt-6 px-10 mb-4">
          <button
            @click="closeEditAdminModal"
            class="px-4 py-2 border border-gray-500 text-gray-600 rounded-md hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            @click="editAdmin"
            class="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Submit
          </button>
        </div>
      </template>
    </modal>

    <!-- Success Modal -->
    <modal
      ref="successModal"
      className="w-11/12 sm:w-[400px] p-6 rounded-md shadow-lg bg-white text-center"
    >
      <template>
        <div class="px-4 py-6">
          <!-- Success Icon -->
          <div class="mx-auto w-16 h-16 mb-4">
            <svg
              class="w-full h-full text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>

          <!-- Success Message -->
          <h3 class="text-xl font-semibold text-gray-900 mb-2">
            Invitation Sent Successfully!
          </h3>
          <p class="text-gray-600 mb-6">
            An email has been sent to the admin. Please accept the invitation to
            complete the setup.
          </p>

          <!-- Email Icon -->
          <div class="mx-auto w-20 h-20 mb-6">
            <svg
              class="w-full h-full text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
          </div>

          <!-- Close Button -->
          <button
            @click="closeSuccessModal"
            class="w-3/4 p-4 mx-auto bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
          >
            Got it!
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'admins',
  data() {
    return {
      searchQuery: '',
      columns: [
        { name: 'name', th: 'Name', sortable: true },
        { name: 'email', th: 'Email', sortable: false },
        { name: 'permissions', th: 'Permissions', sortable: false },
        { name: 'actions', th: 'Actions', sortable: false },
      ],
      roleName: '',
      createAdminForm: {
        email: '',
        role: '',
      },
      editAdminForm: {
        firstName: '',
        lastName: '',
        phone: '',
      },
      roleId: '',
      roles: [],
      selectedUser: null,
      userId: '',
    };
  },
  methods: {
    editAdminModal(user) {
      this.userId = user.id;
      this.editAdminForm = {
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
      };
      this.$refs.editAdminModal.open();
    },

    fetchAdmins() {
      this.$refs.table.loadAjaxData();
    },

    async fetchRoles() {
      try {
        const response = await this.$get({
          url: `${this.$baseurl}/auths/v1/roles`,
          headers: this.headers,
        });
        this.roles = response?.response?.data.data || [];
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    },

    openCreateAdmin() {
      this.createAdminForm = {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
      };
      this.$refs.createAdminModal.open();
    },

    closeCreateAdminModal() {
      this.$refs.createAdminModal.close();
    },

    closeEditAdminModal() {
      this.$refs.editAdminModal.close();
    },

    closeSuccessModal() {
      this.$refs.successModal.close();
    },

    async createAdmin() {
      // Email validation using regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.createAdminForm.email.trim()) {
        alert('Please enter an email address');
        return;
      }

      if (!emailRegex.test(this.createAdminForm.email)) {
        alert('Please enter a valid email address');
        return;
      }

      if (!this.createAdminForm.role) {
        alert('Please select a role');
        return;
      }

      try {
        const payload = {
          email: this.createAdminForm.email,
          roles: [this.createAdminForm.role],
        };

        await this.$post({
          url: `${this.$baseurl}/auths/v1/admin/send-invite`,
          data: payload,
          headers: this.headers,
        });

        this.fetchAdmins();
        this.closeCreateAdminModal();
        this.$refs.successModal.open();
      } catch (error) {
        console.error('Error creating admin:', error);
        alert('Failed to create admin. Please try again.');
      }
    },

    openDeactivateModal(user) {
      this.selectedUser = user;
      this.$refs.deactivateModal.open();
    },

    async handleDeactivate() {
      if (!this.selectedUser || !this.selectedUser.id) {
        console.error('No valid user data provided for deactivation');
        return;
      }

      try {
        await this.$_delete({
          url: `${this.$baseurl}/auths/v1/admin/${this.selectedUser.id}`,
          headers: this.headers,
        });

        this.$refs.deactivateModal.close();
        this.fetchAdmins();
      } catch (error) {
        console.error('Error deactivating admin:', error);
        alert('Failed to deactivate admin. Please try again.');
      }
    },

    async editAdmin() {
      // Email validation using regex

      try {
        const payload = {
          firstName: this.editAdminForm.firstName,
          lastName: this.editAdminForm.lastName,
          phone: this.editAdminForm.phone,
        };

        await this.$put({
          url: `${this.$baseurl}/auths/v1/admin/${this.userId}`,
          data: payload,
          headers: this.headers,
        });

        this.fetchAdmins();
        this.closeEditAdminModal();
      } catch (error) {
        console.error('Error creating admin:', error);
        alert('Failed to create admin. Please try again.');
      }
    },
  },
  computed: {
    usersUrlWithQuery() {
      return this.searchQuery.trim()
        ? `${this.$baseurl}/auths/v1/admin?q=${encodeURIComponent(
            this.searchQuery
          )}`
        : `${this.$baseurl}/auths/v1/admin`;
    },
  },
  mounted() {
    this.fetchRoles();
  },
};
</script>
